/* styles.css */

.root {
  width: 100%;
  overflow-x: auto;
}

.table {
  min-width: 400px;
}

.tableCell {
  font-size: 12px;
  padding: 6px;
  margin: 0;
}

.datePickerStyle {
  padding-bottom: 30px;
}

.numberTextField {
  margin-left: 6px;
  margin-right: 6px;
  width: 150px;
}

.swytchbackInputStyles {
  font-size: 14px;
}

.swytchbackInputLabelStyles {
  font-size: 18px;
}

.labelStyle {
  padding-left: 6px;
  color: #767876;
  font-size: 14px;
  font-weight: 400;
}
