.productTableRow_cellBottomStyle {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  color: #000000;
  font-weight: 400;
}

.productTableRow_swytchbackSelect {
  font-size: 14px;
  text-align: right;
}

.productTableRow_swytchbackMenuItem {
  font-size: 14px;
}

.productTableRow_formControl {
  min-width: 220px;
  max-width: 300px;
}

.productTableRow_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

.removeButtonStyle {
  color: #53585f;
  border: 0;
  padding: 0;
  background-color: transparent;
  &:hover {
    color: #32303f; /* Change background color on hover */
  }
}

.productTableRow_contentBox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  min-height: 140px;
}

.productTableRow_cellContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 100px;
}

.productTableRow_cellTopStyle {
  width: 100%;
  color: #000000;
  font-weight: 600;
}

img.productTableRow_smallButton {
  width: 30px;
}

.productTableRow_rowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.productTableRow_rowContainer .label {
  color: #000000;
  margin-right: 10px;
}

.productTableRow_rowContainer .value {
  color: #000000;
}
