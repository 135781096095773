

.navbar {
  background-color: #1579fb;
  min-width: 320px;
}

.navbar-toggle {
  border: 1px solid #ffffff;
}

.navbar-default .navbar-nav > .active {
  color: #ffffff;
  background: #1994fc;
 }

 .navbar-default .navbar-nav > .active > a,
 .navbar-default .navbar-nav > .active > a:hover,
 .navbar-default .navbar-nav > .active > a:focus {
   color: #BCBEBE;
   background: #1994fc;
 }

 .navbar-default .navbar-nav > li > a {
   color: #ffffff
 }


 .navbar-default .navbar-nav > li > a:hover {
   background-color: #1994fc;
   color: #ffffff;
 }

.navbar-header img {
  padding: 0;
  margin: 0;
}

.navStyles {
  background-color: #1579fb;
  margin: 0;
  padding: 0;
}

.swytchStyle {
  font-size: 24px;
  font-weight: bold;
}

.backStyle {
  font-size: 24px;
  font-weight: lighter;
}

div.headerContainer {
  min-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}