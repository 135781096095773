button.buttonStyle {
  width: 130px;
  margin-left: 5px;
  margin-right: 5px;
}

.spaced {
  background-color: transparent;
  border: 0;
}

.marginRight {
  margin-right: 10px;
}

.colorCircle {
  height: 30px;
  width:30px;
  border-radius: 20px;
}

.container {
  margin: 0px;
  padding: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
}

.responsiveRow {
  box-sizing: border-box;
  display: flex;
  margin: 20px;
  flex-wrap: wrap; /* wrap items to the next line if they overflow */
  gap: 10px; /* space between items */
  border: 1px solid #ffffff;
  border-radius: 10px;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  font-size: 18px;
  color: #ffffff;
}

.responsiveCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  flex: 1 1 200px; /* grow, shrink, and base width */
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
}

/* Default styles for larger screens */
.responsiveCell.large {
  flex: 1 1 calc(40% - 10px); /* Larger items */
}

.responsiveCell.small {
  flex: 1 1 calc(10% - 10px); /* Smaller items */
}

.responsiveItem {
  margin-right: 10px;
}

.paperStyle {
  background-image: linear-gradient(#040303, #595a5a);
  padding-bottom: 10px;
}

.buttonContainerStyle {
  width: 100%;
  padding:20px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  color: #2c3e50;
  border-bottom: 1px solid #ebebeb;
  font-size: 16px;
  margin-bottom: 6px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #f7f7f7;
}

.colorPalette input {
  display: block;
  box-sizing: border-box;
  width: 90px;
  margin: 20px 55px 0;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #ffffff;
  outline: none;
  font: inherit;
  text-transform: uppercase;
  text-align: center;
}

.colorPalette input:focus {
  border-color: #4298ef;
}

/* Media query for smaller screens */
@media (max-width: 600px) {

  .container {
    padding: 6px;
  }

  .responsiveCell {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px 10px 4px 10px;
  }

  .responsiveCell.large {
    flex: 1 1 100%; /* make each item take full width on small screens */
  }

  .responsiveCell.small {
    flex: 1 1 100%; 
  }

}
