.swytchbackModal {
  width: 90vw;
}

.datePickerStyle {
  padding-bottom: 30px;
}

.newSwydgetTitleInputStyle {
  width: 100%;
}

.swytchbackInputStyles {
  color: #53585f;
  font-size: 20px;
  font-weight: 100;
}

.swytchbackInputLabelStyles {
  color: #adb7c1;
  font-size: 20px;
  font-weight: 100;
}

.swytchbackCalendarInputStyles {
  font-size: 14px;
}

.swytchbackCalendarInputLabelStyles {
  font-size: 18px;
}

.labelStyle {
  padding-left: 6px;
  color: #767876;
  font-size: 14px;
  font-weight: 400;
}
