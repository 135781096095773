/* styles.css */

.swytchbackSelect {
  font-size: 14px;
  text-align: right;
}

.swytchbackMenuItem {
  font-size: 14px;
}

.formControl {
  min-width: 220px;
  max-width: 300px;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

.removeButtonStyle {
  color: #53585f;
  border: 0;
  padding: 0;
  background-color: transparent;
  &:hover {
    color: #32303f; /* Change background color on hover */
  }
}

.cellContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cellContainerHorizontal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.cellTopStyle {
  padding-bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  color: #aeaeae;
  font-weight: 600;
}

.cellBottomStyle {
  padding-top: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 40px;
  color: #000000;
  font-weight: 400;
}
