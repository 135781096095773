/* styles.css */

.aggregatedResultsDivStyle {
  padding: 0;
  margin-left: 4px;
  margin-top: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  color: #ffffff;
  font-size: 11px;
  font-weight: normal;
  text-align: left;
}

.progressBarBackgroundColorStyle {
  background-color: #e2effe;
  margin-left: 4px;
  margin-top: 4px;
  margin-right: 4px;
  margin-bottom: 10px;
  height: 6px;
}

.progressBarLikedStyle {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 10px;
  min-width: 10px;
  background-color: #53585f;
  border-radius: 25px;
  font-size: 14px;
}

.progressBarContainerDivStyle {
  margin-top: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
