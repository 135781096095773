/* styles.css */

.datePickerStyle {
  padding-bottom: 30px;
}

.swytchbackInputStyles {
  font-size: 14px;
}

.swytchbackInputLabelStyles {
  font-size: 18px;
}

.labelStyle {
  padding-left: 6px;
  color: #767876;
  font-size: 16px;
  font-weight: 400;
}
