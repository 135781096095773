.App {
  text-align: center;
}

.headerStyles {
}

.footerStyles {
  width: 100%;
  margin-top: 40px;
}


