
.smallLabelStyle {
  padding: 0;
  margin: 4px;
  font-size: 11px;
  font-weight: 400;
}

.dark {
  color: #ffffff;
}

.light {
  color: #53585f;
}




@media screen {
  .progressBarBackgroundColorStyle {
    background-color: #e2effe;
    margin-left: 4px;
    margin-top: 4px;
    margin-right: 4px;
    margin-bottom: 10px;
    height: 6px;
  }

  .progressBarLikedStyle {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 10px;
    min-width: 10px;
    background-color: #157cf8;
    border-radius: 25px;
    font-size: 14px;
  }

  .progressBarDislikedStyle {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 10px;
    min-width: 10px;
    background-color: #ed1c24;
    border-radius: 25px;
    font-size: 14px;
  }
}

@media print {
  .progressBarBackgroundColorStyle {
    background-color: #e2effe;
    border: 1px solid #e2effe;
    margin-left: 4px;
    margin-top: 4px;
    margin-right: 4px;
    margin-bottom: 10px;
    height: 6px;
  }

  .progressBarLikedStyle {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 10px;
    min-width: 10px;
    background-color: #157cf8;
    border: 10px solid #157cf8;
    border-radius: 0px;
    font-size: 14px;
  }

  .progressBarDislikedStyle {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 10px;
    min-width: 10px;
    background-color: #ed1c24;
    border: 10px solid #ed1c24;
    border-radius: 0px;
    font-size: 14px;
  }
}
