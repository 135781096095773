.productCreationModal ul {
  width: 100%;
  padding-left: 10px;
  padding-top: 10px;
  background-color: #dee1e6;
  border: none;
}

/* Inactive tab */
.productCreationModal ul > li > a {
    background-color: #dee1e6;
    color: #606368;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.productCreationModal ul > li > a:hover {
    background-color: #eceef0;
    border: 1px solid #eceef0;
    border-bottom: none;
}

/* Active tab */
.productCreationModal ul > li.active > a,
.productCreationModal ul > li.active > a:hover,
.productCreationModal ul > li.active > a:focus {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

/* Disabled tab */
.productCreationModal ul > li.disabled > a {
    background-color: #00ff00;
    color: darkgray;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
