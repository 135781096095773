.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.rightWidgetContainerStyle {
  padding: 5px;
  margin-top: 20px;
}

.centeredDivStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rowDivStyle {
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: row;
}

.switchLabelStyle {
  color: #0050FF;
  font-size: 16px;
}

.swytchbackInputStyles {
  color: #53585f;
  text-align: left;
  font-size: 24px;
  font-weight: 100;
}

.swytchbackInputLabelStyles {
  font-size: 18px;
}

.datePickersDivStyle {
  height: 100px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: #53585f;
  font-size: 16px;
}

.datePickerDivStyle {
  padding: 0;
  margin-top: 30px;
}

.datePickerStyle {
  width: 200px;
}

.datePickerInputStyles {
  color: #53585f;
  text-align: left;
  font-size: 14px;
  font-weight: 200;
}

.datePickerInputLabelStyles {
  font-size: 18px;
  color: #767876;
  margin-bottom: 12px;
  font-weight: 200;
}

.textField {
  margin: 0;
}

.iconButtonContainer {
  margin: 0;
  padding: 0;
}

.spaced {
  margin: 0;
  padding: 4px;
}

.icon {
  margin: 6px;
  font-size: 26px;
}

.backdrop {
  z-index: 1000;
  color: #ffffff;
}

.title {
  color: #2c3e50;
  border-bottom: 1px solid #ebebeb;
  font-size: 16px;
  margin-bottom: 6px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #f7f7f7;
}

.buttonContainerStyle {
  padding: 10px;
}

.buttonStyle {
  margin-bottom: 6px;
  margin-left: 6px;
  margin-right: 6px;
  font-size: 14px;
}