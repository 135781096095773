/* Swydget */

.slider {
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.thumb {
  background-color: #1e70fb;
  height: 6vh;
  width: 6vh;
  border: 0.7vh solid #ffffff;
  margin-left: -2vh;
  margin-top: 0;
}

.track {
  height: 2vh;
  border: 0.5vh solid #ffffff;
  border-radius: 20px;
}

.rail {
  height: 2vh;
  border: 0.5vh solid #ffffff;
  background-color: #1657c8;
  border-radius: 20px;
  opacity: 1.0;
}


/* SwydgetWelcome */
.close {
  padding: 10px;
}

.linkStyle {
  color: #ffffff;
}

.linkStyle:hover {
  color: #ffffff;
}

.fullWidthSnackBar {
  margin: 0;
}

.messageContainer {
  text-align: left;
  margin-bottom: 20px;
  font-size: 16px;
}

.buttonContainer{
  
}

.snackbarContainer {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

/* SwydgetContestEntry */

.enabledButton {
  width: 100%;
  height: 50px;
  font-size: 18px;
  font-weight: 500;
  background-color: #1994fc;
  color: #ffffff;
  border-radius: 6px;
  border: 0;
}

.enabledButton:hover {
  background-color: #1994fc;
}
