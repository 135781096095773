trStyle {
  height: 100px;
  border-bottom: 1px solid #b7b7b7;
}

.disabledTrStyle {
  height: 100px;
  border-bottom: 1px solid #b7b7b7;
  background-color: #cccccc;
}

.tdCardStyle {
}

.tdNameStyle {
}