.section {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 30px;
}