/* Swytchback Modal styles */
.swytchbackModal90w {
  width: 90vw;
}

.swytchbackModalHeader {
  background-color: #F7F7FA;
}

div.feedbackContainer {
  min-width: 50px;
  min-height: 50px;
}

.swytchbackModalTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-weight: 100;
  color: #606368;
}

.swytchbackModalBody {
  padding: 0px;
  margin: 0px;
}

.swytchbackModalBody .bigDog{
  flex-grow: 3;
}
div.swytchbackGradientPane {
  background-image: linear-gradient(#040303, #595a5a);
}

.coloredBackground {
  background-color: #ffffff;
  color: #31353d;
}

.swytchbackModalBody div.contentContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
  overflow-y: auto;
  overflow-x: hidden;
}

.swytchbackModalBody div.contentContainerCentered {
  height: 58vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.swytchbackModalBody div.contentContainerNoMargin {
  height: 65vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
  overflow-y: auto;
  overflow-x: hidden;
}

.swytchbackModalBody div.divRowStyle {
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}

.swytchbackModalBody div.divRowStyleMiddle {
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.swytchbackModalBody div.divColumnStyle {
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.swytchbackModalBody div.fixedTopContainer {
  width: 100%;
  min-height: 100px;
  padding: 40px 100px 20px 100px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

}

.swytchbackModalBody div.centeredContainerDivStyle {
  margin-top: 0px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 400px;
}

.swytchbackModalBody div.activityIndicatorMessage {
  margin: 30px;
  font-size: 16px;
  color: #911313;
}

.swytchbackModalBody div.cardsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.swytchbackModalBody div.cardDiv {
  height: 58vh;
  padding: 20px 20px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.swytchbackModalBody .controlButton {
  height: 40px;
  width: 40px;
  padding: 0px;
  margin-left: 20px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.swytchbackModalBody div.rowContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px;
}

.swytchbackModalBody div.rowContainerCentered {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0px;
  margin: 0px;
}

.swytchbackModalBody div.mediumHeightDiv {
  height: 20vh;
}

.swytchbackModalBody div.rowContainerSpaced {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px;
}

.swytchbackModalBody div.columnContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
}

.swytchbackModalBody div.columnCenteredContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
}

.swytchbackModalBody div.tallModalHeight {
  height: 70vh
}

.swytchbackModalBody div.standardModalHeight {
  height: 55vh
}

.swytchbackModalBody div.shortModalHeight {
  height: 40vh
}

.swytchbackModalBody div.mainPane {
  width: 70%;
  overflow-y: auto;
}

.swytchbackModalBody div.rightPane {
  border-left: 1px solid #ffffff;
  width: 40%;
}

.swytchbackModalBody div.leftPane {
  width: 25%;
}

.swytchbackModalBody .marginBottom {
  margin-bottom: 10px;
}

.swytchbackModalBody .marginRight {
  margin-right: 10px;
}

.swytchbackModalBody .marginRight {
  margin-right: 10px;
}

.swytchbackModalBody div.fullWidth {
  width: 100%;
}

.swytchbackModalBody div.narrow {
  width: 30%;
}

.swytchbackModalBody div.wide {
  width: 70%;
}

.swytchbackModalBody div.fullWidthPane {
  width: 100%;
}

.swytchbackModalBody div.halfPane {
  width: 50%;
  padding: 0px;
}

.swytchbackModalBody div.borderRight {
  border: 1px solid #606368;
}


.swytchbackModalBody div.title {
  color: #ffffff;
  font-size: 16px;
  font-weight: 800;
  margin-right: 10px;
}

.swytchbackModalBody div.subtitle {
  color: #ffffff;
  font-size: 14px;
  font-weight: 800;
  margin-right: 10px;
}

.swytchbackModalBody div.instruction {
  color: #ffffff;
  font-size: 14px;
  font-weight: 100;
}

.swytchbackModalBody div.content {
  color: #ffffff;
}

.swytchbackModalBody div.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.swytchbackModalBody div.content div.gradientContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 4px;
  border: 1px solid #606368;
  background-image: linear-gradient(#195DFE, #0120BB); 
  min-height: 120px;
}

.swytchbackModalBody .swytchbackSelect {
  font-size: 14px;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding-left: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 0px;
  text-align: center;
  height: 58px;
}

.swytchbackModalBody .swytchbackInstruction {
  margin-left: 20px;
  font-size: 12px;
}

.swytchbackModalBody div.widget {
  /* border: 1px solid #606368; */
  padding: 20px;
  color: #ffffff;
  border-radius: 5px;
  /* box-shadow: 2px 2px 4px 4px rgba(0,0,0,0.2); */
}

.swytchbackModalBody div.widget div.titleContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 0px;
  width: 100%;
}

.swytchbackModalBody div.widget div.contentContainer {
  border: 3px solid #0167EC;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.swytchbackModalBody div.widget .swytchbackButton {
  font-size: 14px;
  color: #ffffff;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

.swytchbackModalBody div.widget .textFieldHeight {
  height: 58px;
}

.swytchbackModalBody div.widget .swytchbackButtonActive {
  background-color: #0167EC;
}

.swytchbackModalBody div.widget .left {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-right: 0px;
}

.swytchbackModalBody div.widget .right {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-left: 0px;
}

.swytchbackModalBody div.widget .fullBorder {
  border-radius: 10px;
}

.swytchbackModalBody div.widget .middle {
  border-radius: 0px;
}

.swytchbackModalFooter {
  background-color: #eceef0;
  color: #606368;
}

/* Swytchback Tabs */

.swytchbackModalTabs {
  background-color: #dee1e6;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.tab-content {
  width: 100%;
}

/* Selected Tab */
.swytchbackModalTabs .nav-link {
  width: 100%;
  padding-left: 10px;
  padding-top: 10px;
  background-color: #dee1e6;
  color: #606368;
}

.swytchbackModalTabs .nav-link:hover {
  background-color: #eceef0;
  border: 1px solid #eceef0;
}


/* Inactive tab */
.swytchbackModalTabs ul > li > a {
    background-color: #dee1e6;
    color: #606368;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.swytchbackModalTabs ul > li > a:hover {
    background-color: #eceef0;
    border: 1px solid #eceef0;
    border-bottom: none;
}

/* Active tab */
.swytchbackModalTabs ul > li.active > a,
.swytchbackModalTabs ul > li.active > a:hover,
.swytchbackModalTabs ul > li.active > a:focus {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

/* Disabled tab */
.swytchbackModalTabs ul > li.disabled > a {
    background-color: #00ff00;
    color: darkgray;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

/* Styles for Swytchback Modals */
div.rowContentContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.swytchbackModalTabs div.marginBottom {
  margin-bottom: 10px;
}

.swytchbackModalTabs div.tabContentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

div.rightPaneContainer {
  margin: 0px;
  padding: 0px;
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: auto;
}

div.leftPaneContainer {
  margin: 0px;
  padding: 0px;
  width: 30%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.swytchbackModalTabs div.fullPaneContainer {
  margin: 0px;
  padding: 0px;
  width: 100%;
  color: #606368;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 2px solid #dee1e6;
}

.swytchbackModalTabs div.hookFullPaneContainer {
  margin: 0px;
  padding: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
}

.swytchbackModalTabs div.hookContentContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.swytchbackModalTabs div.row {
  flex-direction: row;
}

.swytchbackModalTabs div.column {
  flex-direction: column;
}

div.topThird {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div.bottomTwoThirds {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 100px;
}

.swytchbackModalTabs div.contentForm {
  width: 90%;
  margin-top: 40px;
  margin-bottom: 40px;
}

div.alertMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
}

.swytchbackDropzoneContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.swytchbackDropzoneMessage {
  width: 100%;
  font-size: 16px;
  font-weight: 900;
}




@media only screen and (max-width: 768px) {
  div.rowContentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .swytchbackModalTabs div.leftPaneContainer {
    width: 100%;
  }

  .swytchbackModalTabs div.rightPaneContainer {
    width: 100%;
  }


}
