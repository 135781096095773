

.swytchbackInputStyles {
  color: #53585f;
  text-align: left;
  font-size: 18px;
  font-weight: 100;
  margin: 0px;
}

.swytchbackInputLabelStyles {
  font-size: 14px;
}

.textField {
  margin: 60px;
  width: 100%;
}

.submitButton {
  margin-top: 12px;
  width: 100%;
}