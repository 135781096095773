.formControlLabel {
  font-size: 16px;
  padding-top: 5px;
}

.formControlLabel label {
  font-size: 16px;
}

.root {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.5);
}

.textField {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.swytchbackInputStyles {
  height: 10vh;
  font-size: 16px;
  line-height: 1.4;
  color: #ffffff;
}

.swytchbackInputLabelStyles {
  font-size: 18px;
}

.notchedOutline {
  border-width: 1px !important;
  border-color: #ffffff !important;
}
