.containerDivStyle {
  height: 65vh;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imageStyle {
  max-height: 100%;
  max-width: 100%;
}

.responsiveIframeStyle {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 70vh;
  /* minHeight: -webkit-fill-available; */ /* Commented out because it may not be fully supported */
  overflow: hidden;
}
