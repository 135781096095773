.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.textField {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  width: 400px;
}

.swytchbackInputStyles {
  font-size: 16px;
  line-height: 1.4;
}

.swytchbackInputLabelStyles {
  font-size: 18px;
}

.textFieldPopover {
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.swytchbackPopoverInputStyles {
  font-size: 14px;
}

.swytchbackPopoverInputLabelStyles {
  font-size: 14px;
}

.accordionRoot {
  width: 100%;
  background-color: #dee1e6;
}

.accordionHeading {
  font-size: 16px;
  font-weight: 500;
}

.columnContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}