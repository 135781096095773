.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.tableHeaderContainerStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-bottom: 10px;
}

.controlLabelStyle {
  font-size: 14px;
  font-weight: 400;
  color: #767676;
}

.formControlContainerStyle {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

.tableStyle {
  background-color: #ffffff;
  margin: 0;
}

.theadStyle {
  background-color: #e0e5ee;
  color: #53585f;
  font-size: 14px;
}

.theadStyleTr {
  height: 44px;
}

.theadImgStyleTh {
  width: 160px;
  font-weight: 400;
}

.theadsStyleTh {
  font-weight: 400;
}

.circularProgressDivStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}