div.rightButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  width: '100%';
  padding: 20px 20px 0px 20px;
}

button.fullWidth {
  width: 100%;
}

button.swytchbackActiveButton,
button.swytchbackActiveButton:focus {
  color: #0050FF;
  font-weight: 400;
  background-color: #ffffff;
  border: 2px solid #0064FF;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  outline: none !important;
}

button.swytchbackActiveButton:hover {
  color: #006EFF;
  font-weight: 400;
  background-color: #F6F7F7;
  border: 2px solid #0064FF;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  outline: none !important;
}

button.swytchbackBorderlessActiveButton,
button.swytchbackBorderlessActiveButton:focus {
  color: #0050FF;
  font-weight: 400;
  width: 100%;
  background-color: #ffffff;
  border: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 0px;
  margin-right: 0px;
  outline: none !important;
}

button.swytchbackBorderlessActiveButton:hover {
  color: #aeaeae;
  font-weight: 400;
  width: 100%;
  background-color: #ffffff;
  border: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 0px;
  margin-right: 0px;
  outline: none !important;
}

button.swytchbackGreenButton,
button.swytchbackGreenButton:focus {
  color: #ffffff;
  font-weight: 400;
  width: 100%;
  background-color: #0c8f07;
  border: 0;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 0px;
  margin-right: 0px;
  outline: none !important;
}

button.swytchbackGreenButton:hover {
  color: #ffffff;
  font-weight: 400;
  width: 100%;
  background-color: #1bb115;
  border: 0;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 0px;
  margin-right: 0px;
  outline: none !important;
}

button.swytchbackEmphasisButton:hover {
  color: #006EFF;
  font-weight: 400;
  width: 100%;
  background-color: #F6F7F7;
  border: 2px solid #008CFF;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 0px;
  margin-right: 0px;
  outline: none !important;
}

button.swytchbackEmphasisButton,
button.swytchbackEmphasisButton:focus {
  color: #ffffff;
  font-weight: 400;
  width: 100%;
  background-color: #0064FF;
  border: 2px solid #0064FF;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 0px;
  margin-right: 0px;
  outline: none !important;
}

button.swytchbackInactiveButton,
button.swytchbackInactiveButton:disabled,
button.swytchbackInactiveButton:focus {
  color: #88889f;
  font-weight: 400;
  background-color: #ffffff;
  border: 2px solid #c2c2d3;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 0px;
  margin-right: 0px;
  outline: none !important;
}

button.swytchbackInactiveButton:hover {
  color: #88889f;
  font-weight: 400;
  background-color: #ffffff;
  border: 2px solid #c2c2d3;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 0px;
  margin-right: 0px;
  outline: none !important;
}

button.swytchbackPrimaryActionButton,
button.swytchbackPrimaryActionButton:focus {
  color: #ffffff;
  font-weight: 400;
  background-color: #0059ff;
  border: 2px solid #0059ff;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 0px;
  margin-right: 0px;
  outline: none !important;
}

button.swytchbackPrimaryActionButton:hover {
  color: #ffffff;
  font-weight: 400;
  background-color: #1579fb;
  border: 2px solid #0059ff;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 0px;
  margin-right: 0px;
  outline: none !important;
}

button.swytchbackAlertButton,
button.swytchbackAlertButton:focus {
  color: #ffffff;
  font-weight: 400;
  background-color: #ff0000;
  border: 2px solid #ff0000;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 0px;
  margin-right: 0px;
  outline: none !important;
}

button.swytchbackAlertButton:hover {
  color: #ffffff;
  font-weight: 400;
  background-color: #ee2233;
  border: 2px solid #ff0000;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 0px;
  margin-right: 0px;
  outline: none !important;
}

button.oneThird {
  width: 30%;
}

button.half {
  width: 50%;
}

div.labelStyle {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  width: 50%;
}

div.switchDivStyle {
  border-bottom: 1px solid #ffffff;
  padding-left: 0;
  padding-right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  display:  flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

div.switchLeftDivStyle {
  padding-left: 0;
  padding-right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  display:  flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

div.swytchbackToolBarLeftJustified {
  margin: 0;
  padding: 0;
  display:  flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

div.fileTable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 10px 30px 10px;
}

div.fileTable div.fileEntry {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

div.fileTable div.fileEntry div.title {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

div.fileTable div.fileEntry div.button {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 5px;
}

div.fileTable div.fileEntry div.button button {
  color: #ffffff;
  font-weight: 400;
  background-color: transparent;
  border: none;
  padding: 6px;
}

div.swytchbackFileUpload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

div.swytchbackFileUploadText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
}

div.swytchbackFileUpload .swytchbackFileInput {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 10px;
  margin: 0px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none !important;
  width: 100%;
}

div.swytchbackFileUpload .swytchbackFileInput:hover {
  background-color: #595a5a;
}

div.swytchbackFileUpload input[type="file"] {
  display: none;
}

div.swytchbackAdminContainer { 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

div.swytchbackAdminControl {
  margin-bottom: 0px;
  width: 100%;
  color:#ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
}

div.swytchbackAdminControl .container {
  width: 100%;
  padding: 0px;
}

div.swytchbackAdminControl .container .title {
  color:#ffffff;
  font-size: 14px;
  font-weight: 500;
}

div.swytchbackAdminControl div.togglesContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px;
}

div.swytchbackAdminControl .keyValuePairContainer {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}

div.swytchbackAdminControl .controlFieldTitleStyle {
  font-size: 14px;
  font-weight: 300;
}

div.swytchbackAdminControl div.rowContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

div.swytchbackAdminControl div.spaceBetween {
  justify-content: space-between;
}

div.swytchbackAdminControl div.columnContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div.swytchbackAdminControl div.labelStyle {
  width: 10%;
  text-align: center;
  font-weight: 300;
  font-size: 20px;
  color: #ffffff;
}


div.swytchbackAdminControl .inputStyle {
  height: 50px;
  margin-top: 15p;
  background-color: #ffffff;
  border: 2px solid #dddee0;
  font-weight: 300;
  font-size: 18px;
}

div.swytchbackAdminControl .buttonStyle {
  height: 50px;
  width: 40px;
  padding: 0;
  margin: 0;
}

div.swytchbackAdminControl .wideButtonStyle {
  height: 55px;
  width: 80px;
}

div.swytchbackAdminControl .emojiPickerStyle {
  z-index: 1000;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

div.swytchbackAdminControl .emojiIconStyle {
  height: 18px;
  margin: 0;
  padding: 0
}




