div.swydgetsTable div.topToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: '100%';
  padding: 0px 0px 0px 0px;
}

div.swydgetsTable div.filterControlDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: '100%';
  padding: 0px 0px 20px 0px;
}

div.swydgetsTable div.filterControlDiv div.searchNameFilterDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-width: 500px;
  margin-right: 10px;
}

div.swydgetsTable div.filterControlDiv div.ownerFilterDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-right: 10px;
}

div.swydgetsTable div.filterControlDiv div.sortFilterDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 0;
}

div.swydgetsTable div.topToolbar div.leftContentContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  color: #a1b2b7;
  font-size: 14px;
  font-weight: 800;
  padding: 0px 0px 0px 0px;
}

div.swydgetsTable div.topToolbar div.rightContentContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  color: #a1b2b7;
  font-size: 14px;
  font-weight: 800;
  padding: 0px 0px 0px 0px;
}

div.swydgetsTable .glyphButtonStyle {
  height: 30px;
  width: 30px;
  padding: 0px;
  margin-left: 5px;
  color: #a1b2b7;
  background-color: #ffffff;
  border: 0;
}

div.swydgetsTable .controlLabelStyle {
  font-size: 14px;
  font-weight: 400;
  color: #767676;
}

div.swydgetsTable .controlStyle {
  width: 100%;
  font-size: 14px;
  height: 54px;
}

.swytchbackMenuItem.MuiMenuItem-root {
  font-size: 14px;
}
div.swydgetsTable input {
  height: 20px;
  font-size: 14px;
}

/* div.swydgetsTable input::placeholder {
  font-size: 14px;
} */


