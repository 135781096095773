.label {
  font-size: 14px;
}

.buttonRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
