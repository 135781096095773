div.tableContainerStyle {
  border: 10px solid #afb8c8;
}

div.centeredContainerDivStyle {
  min-height: 400px;
  margin: 0;
  padding: 30px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

div.captionDivStyle {
  font-size: 20px;
  font-weight: 400;
  padding: 20px;
}

div.imagePreviewStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 6px solid #ffffff;
  margin-top: 0;
  margin-bottom: 0px;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  overflow: hidden;
  -webkit-box-shadow: 1px 1px 3px 3px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 1px 1px 3px 3px #ccc;  /* Firefox 3.5 - 3.6 */
  box-shadow: 1px 1px 3px 3px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

img.addContentImageStyle {
  width: 50px;
  padding-top: 0px;
  margin-bottom: 20px;
}

div.addContentInstructionsDiv {
  color: #0c34fb;
  font-size: 16px;
}

.clearButtonStyle {
  color: #0059ff;
  width: 30vh;
  background-color: #ffffff;
  border-color: #0059ff;
  margin-bottom: 30px;
  margin-top: 30px;
}

div.imageContainerDivStyle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 40vh;
  height: 40vh;
  overflow: hidden;
}

div.imagePreviewNoMarginStyle {
  width: auto;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-box-shadow: 1px 1px 3px 3px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 1px 1px 3px 3px #ccc;  /* Firefox 3.5 - 3.6 */
  box-shadow: 1px 1px 3px 3px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

div.thumbnailImgStyle {
  position: relative;
  width: 40vh;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border: 6px solid #ffffff;
}


img.imageStyle {
  width: 100%;
  object-fit: contain;
}
