.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.tableContainerStyle {
  width: 300px;
}

.centeredContainerDivStyle {
  margin-top: 0;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.swytchbackLeftJustifiedContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
}

.toggleRowDivStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 60px;
  width: 100%;
}

.columnLabelDivStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
}

.columnDataDivStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
}

.textField {
  margin: 0;
  width: 40vw;
}

.swytchbackInputStyles {
  color: #53585f;
  text-align: left;
  font-size: 18px;
  font-weight: 100;
}

.swytchbackInputLabelStyles {
  font-size: 20px;
}

.buttonStyle {
  margin-left: 5px;
  margin-right: 5px;
}

.tableFooterBlockDivStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
}

.tableFooterRootDivStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
}

.buttonRowDivStyle {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cogButtonStyle {
  color: #53585f;
  border: 0;
  background-color: #ffffff;
  padding: 0;
  margin: 0;
}

.toolkitContainerDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

.glyphStyleSmall {
  font-size: 12px;
  margin: 2px;
  color: #53585f;
}

.glyphStyleMedium {
  font-size: 16px;
  margin: 2px;
  color: #53585f;
}

.glyphStyleLarge {
  font-size: 20px;
  margin: 2px;
  color: #53585f;
}

.dropZoneContainer {
  padding: 10px;
  margin: 0;
}

.imageList {
  margin: 0;
  margin-top: 20px;
  border: 1px solid black;
  border-radius: 5px;
  min-height: 100px;
}

.backdrop {
  z-index: 1000;
  color: #ffffff;
}

.rowDivStyle {
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: row;
}

.iconButtonContainer {
  margin: 0;
  padding: 0;
}

.spaced {
  margin: 0;
  padding: 4px;
}

.icon {
  margin: 6px;
  font-size: 20px;
}
