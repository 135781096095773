.formControlLabel {
  padding-top: 5px;
  text-align: left;
  color: #ffffff;
}

.root {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
