
.textField {
  width: 100%;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  padding: 20px 1vw 20px 1vw;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 1vw 20px 1vw;
}

