.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.textField,
.numberTextField {
  margin-left: 6px;
  margin-right: 6px;
}

.numberTextField {
  width: 100px;
}

.swytchbackInputStyles {
  font-size: 16px;
}

.swytchbackInputLabelStyles {
  font-size: 18px;
}

.swytchbackLeftJustifiedContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
