
@media screen {



}

@media print {
  .page-break {
    page-break-after: always;
  }
  @page { size: portrait; }

  div.card-container {
    border: 1px solid black;
  }
}

@media screen, print {

  div.sectionContainer {
    padding-top: 40px;
    padding-left: 100px;
    padding-bottom: 20px;
    padding-right: 100px;
    margin: 0px;
    page-break-inside: avoid;
  }

  div.swydgetNameContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px;
    margin-top: 0px;
    font-size: 16px;
    font-weight: 500;
  }
  div.swydgetTitleContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;
    margin-top: 50px;
    font-size: 32px;
    font-weight: 800;
  }

  div.blockTitleContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;
    margin-top: 50px;
    font-size: 24px;
    font-weight: 800;
  }

  div.divEditListContainer {
    max-height: 550px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
  }

  div.sectionTitle {
    padding: 40px 0px 0px 0px;
    margin: 0px 0px 20px 0px;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid black;
  }

  div.divQuote {
    font-weight: 500;
    font-size: 14px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 20px;
  }

  table.swytchbackTable {
    width: 100%;
  }

  table.swytchbackTable table.swytchbackInfoTable {
    margin: 6px 0px 24px 0px;
  }

  table.swytchbackTable td.sectionTitle {
    padding: 40px 20px 0px 0px;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid black;
  }

  table.swytchbackTable td.key {
    padding: 6px 20px 6px 0px;
    font-size: 14px;
    font-weight: 700;
  }

  table.swytchbackTable td.value {
    padding: 6px 20px 6px 0px;
    font-size: 14px;
    font-weight: 700;
  }

  table.adminTable {
    background-color: transparent;
    color: #ffffff;
    margin: 0px;
    width: 100%;
  }

  table.adminTable thead {
    background-color: transparent;
    color: #ffffff;
    font-size: 14px;
  }

  table.adminTable tr.tHeadTr {
    height: 44px;
    border-bottom: 1px solid #ffffff
  }

  table.adminTable thead th.tHeadLeft {
    padding: 5px 10px 5px 0px;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
  }

  table.adminTable thead th.tHeadRight {
    padding: 5px 10px 5px 10px;
    font-size: 16px;
    text-align: right;
    font-weight: 700;
  }

  table.adminTable tbody tr {
    border-bottom: 1px solid #ffffff;
  }

  table.adminTable tbody td.tdLeft {
    padding: 5px 10px 5px 0px;
    text-align: left;
    vertical-align: middle;
  }

  table.adminTable tbody td.tdRight {
    padding: 5px 10px 5px 10px;
    text-align: right;
    vertical-align: middle;
  }

}
