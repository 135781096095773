div.centeredContainerDivStyle {
  min-height: 40vh;
  margin: 0;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

div.captionDivStyle {
  color: #ffffff;
  font-size: 20px;
  font-weight: 800;
  padding: 20px;
}

div.imagePreviewStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  border-radius: 10px;
  margin-top: 0;
  margin-bottom: 0px;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  overflow: hidden;
}

img.addContentImageStyle {
  width: 50px;
  padding-top: 0px;
  margin-bottom: 20px;
}

div.addContentInstructionsDiv {
  color: #ffffff;
  font-size: 16px;
}

div.imageContainerDivStyle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 40vh;
  height: 40vh;
  overflow: hidden;
}

img.imageStyle {
  width: 100%;
  object-fit: contain;
}

.buttonStyle {
  width: 100px;
  height: 100px;
  color: #ffffff;
  border: 0;
  padding: 0;
  background-color: transparent;
  &:hover {
    color: #32303f; /* Change background color on hover */
  }
}

.swytchbackDropzoneContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.swytchbackDropzoneMessage {
  width: 100%;
  font-size: 16px;
  font-weight: 900;
}

.paddedItem {
  color: #ffffff;
  padding: 20px;
  margin: 0px;
}

.rightPaddedItem {
  color: #ffffff;
  padding-right: 20px;
  margin: 0px;
}

.marginBottom {
  margin-bottom: 50px;
}
