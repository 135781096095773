.scalarDescriptionInputStyle {
  width: 100%;
  background-color: #ffffff;
}

.swytchbackInputStyles {
  color: #53585f;
  font-size: 18px;
  font-weight: 100;
}

.swytchbackInputLabelStyles {
  color: #adb7c1;
  font-size: 18px;
  font-weight: 100;
}
