.datePickerDivStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin-top: 30px;
}

.datePickerStyle {
  width: 200px;
}

.datePickerInputStyles {
  color: #53585f;
  text-align: left;
  font-size: 14px;
  font-weight: 200;
}

.datePickerInputLabelStyles {
  font-size: 18px;
  color: #767876;
  margin-bottom: 12px;
  font-weight: 200;
}

.labelStyle {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  width: 50%;
}