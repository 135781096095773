.media-modal {
  width: 90vw;
}

.modal-backdrop {
  opacity:0.9 !important;
}

.iframe-container {
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  background-color: #aeaeae;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 0;
}

.titleDivStyle {
  width: 550px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0px;
  opacity: 1.0;
  transition: opacity 1.0s;
  -webkit-transition: opacity 1.0s;

}
button.swydgetButtonStyle,
button.swydgetButtonStyle:hover
button.swydgetButtonStyle:focus {
  border: 1px solid white;
  padding: 10px;
  margin: 10px;
}

button.green,
button.green:hover,
button.green:focus {
  background-color: #008CFF;
  border: 1px solid white;
} 

button.red,
button.red:hover,
button.red:focus {
  background-color: red;
  border: 1px solid white;
}

.fadeOut {
  opacity: 0.0;
}

div.cardStyle {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  border-radius: 5%;
  position: 'relative'
}

div.cardStyleAbsolute {
  position: absolute;
}

div.cardStyleShadow {
  box-shadow: 0px 3px 20px 10px rgba(0,0,0,0.2);
}

@media (max-width: 768px) {
  .titleDivStyle {
    width: unset;
  }
  
}

@media only screen and (max-width: 768px) {
  .media-modal {
    width: auto;
  }

  .iframe-container {
    position: relative;
    padding: 0;
    margin: 0;
    width: 90vw;
    height: 126vw;
    overflow: hidden;
    border: 0;
    background-color: #aeaeae;
  }

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 90vw;
    height: 126vw;
    overflow: hidden;
    border: 0;
  }
  
}
