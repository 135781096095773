
div.nextButtonContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
  font-size: 40px;
}

div.nextButtonContainerStyle .circle {
  width: 36px;
  height: 36px;
  color: #ffffff;
  margin-bottom: 0px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 30;
}

div.nextButtonContainerStyle .pulse {
  animation: pulse-animation 2s infinite;
}