.containerDivStyle {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentDivStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}

.formControl {
  width: 100%;
  height: 60vh;
}

.choiceRowStyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 5px;
}

.instructionDivStyle {
  font-size: 16px;
  font-weight: 800;
}

.freeResponseMeatDivStyle {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonToolbarStyle {
  margin: 10px;

}

.promptStyle {
  font-weight: 800;
  padding: 6px;
  margin-right: 10px;
}

.choicesContainerStyle {
  position: absolute;
  bottom: 0;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.mcSubmitButtonDiv {
  position: absolute;
  right: 0px;
  bottom: 0px;
  margin: 0px;
  padding-right: 20px;
  padding-bottom: 11px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 50px;
  color: #ffffff;
  font-size: 20px;
  z-index: 20;
}

.mcSubmitButtonDiv button {
  width: 26px;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  border: 0px
}

.freeResponseButtonDiv {
  position: absolute;
  right: 0px;
  bottom: 0px;
  margin: 0px;
  padding-right: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 50px;
  color: #ffffff;
  font-size: 20px;
  z-index: 20;
}

.freeResponseButtonDiv button {
  width: 26px;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  border: 0px
}

.roundedCornersStyle {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.choicesTrayStyle {
  margin: 0px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background-color:rgba(0,0,0,0.8);
  color: #ffffff;
  transition: height 0.5s;
}

.choicesTrayStyleNoTransition {
  margin: 0px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background-color:rgba(0,0,0,0.8);
  color: #ffffff;
}

.fixedTitleDivStyle {
  margin: 0px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background-color:rgba(0,0,0,0.8);
  color: #ffffff;
  transition: height 0.5s;
}

.choicesDivStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  overflow: auto;
}

.expanded {
  height: 50vh;
}

.collapsed {
  height: 1vh;
}

.countLabel {
  width: 30px;
  margin-top: 10px;
  margin-right: 8px;
  font-size: 10px;
}

.mcChoicesHeader {
  color: #ffffff;
  background-color:rgba(0,0,0,0.8);
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 10;
}

.mcGlyphStyle {
  font-size: 30px;
}

.mcGlyphStyleGray {
  font-size: 30px;
  color: #aeaeae;
}

.forcedChoiceNextGlyphStyle {
  font-size: 34px;
}

.textAreaStyle {
  height: 80px;
  color: #000000;
  background-color: #ffffff;
  border: 2px;
  border-radius: 6px;
  border-style: solid;
  border-color: #dddee0;
  font-weight: 300;
  font-size: 18px;
}

.circle {
  width: 20px;
  height: 20px;
  color: #ffffff;
  margin-bottom: 8px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 30;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.9);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
}
