.rightJustifiedContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.marginBottom {
  margin-bottom: 20px;
}
