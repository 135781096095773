.divPanelStyle {
  margin: 6px;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  web-kit-box-shadow: 1px 1px 3px 3px #ccc;
  moz-box-shadow: 1px 1px 3px 3px #ccc;
  box-shadow: 1px 1px 3px 3px #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.divHeaderStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid black;
}

.divBodyStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.divFooterStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.divSubsectionStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
}

.divHeaderButtonContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.divTableRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.surveyName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 40%;
}

.surveyInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 60%;
}

.surveyInfo div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
  margin-left: 20px;
}

.divTableHeaderRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.surveyNameHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  text-decoration: underline;
  width: 40%;
}

.surveyInfoHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 60%;
}

.surveyInfoHeader div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-weight: 600;
  text-decoration: underline;
  width: 100px;
  margin-left: 20px;
}

.logoStyle {
  max-width: 200px;
}

.disconnectButtonStyle {
  margin: 20px 0px 0px 0px;
}

.buttonStyle {
  width: 100px;
  height: 100px;
}

.enabledButtonStyle {
  color: #028a04;
  border: 0;
  background-color: #ffffff;
  padding: 0;
  margin: 0;
}

.disabledButtonStyle {
  color: #aeaeae;
  border: 0;
  background-color: #ffffff;
  padding: 0;
  margin: 0;
}

.glyphStyle {
  font-size: 30px;
  margin: 2px;
}

div.feedbackContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

div.feedbackContainer div.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

div.feedbackContainer div.message {
  font-size: 14px;
  font-weight: 300;
}
