
.removeButtonStyle {
  color: #ffffff;
  border: 0;
  padding: 0;
  background-color: transparent;
  &:hover {
    color: #53585f; /* Change background color on hover */
  }
}
