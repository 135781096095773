.divModalTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-right: 20px;
}

.buttonStyle {
  width: 130px;
  border: 1px solid black;
}
