.slider {
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.thumb {
  background-color: #1e70fb;
  height: 6vh;
  width: 6vh;
  border: 0.7vh solid #ffffff;
  margin-left: -2vh;
  margin-top: 0;
}

.track {
  height: 2vh;
  border: 0.5vh solid #ffffff;
  border-radius: 20px;
}

.rail {
  height: 2vh;
  border: 0.5vh solid #ffffff;
  background-color: #1657c8;
  border-radius: 20px;
  opacity: 1.0;
}
