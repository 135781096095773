div.sectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div.leftSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

div.chartContainer {
  width: 90%;
  min-width: 700px;
  /* min-height: 1000px; */
}

div.controlPanelContainer {
  margin: 20px;
}

div.centeredRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

div.centeredColumnContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div.label {
  font-size: 14px;
  font-weight: 900;
  margin-right: 10px;
}

div.buttonRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

div.turfResultContainer{
  margin: 4px; 

}

