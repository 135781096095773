.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.section {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.segmentContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.segmentDiv {
  width: 200px;
}

.textFieldPopover {
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.swytchbackPopoverInputStyles {
  font-size: 14px;
}

.swytchbackPopoverInputLabelStyles {
  font-size: 14px;
}