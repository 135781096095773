.textField {
  margin: 0;
  width: 100%;
  color: #ffffff;
}

/* Styles for the fieldset inside .MuiOutlinedInput-root */
.textField .MuiOutlinedInput-root fieldset {
  border-color: #ffffff;
  border-radius: 10px;
}

/* Styles for the fieldset inside .MuiOutlinedInput-root on hover */
.textField .MuiOutlinedInput-root:hover fieldset {
  border-color: #ffffff;
}

/* Styles for the fieldset inside .MuiOutlinedInput-root when focused */
.textField .Mui-focused fieldset {
  border-color: #ffffff;
}

/* Styles for the fieldset inside .MuiOutlinedInput-root when disabled */
.textField .Mui-disabled fieldset {
  border-color: #ffffff;
}

.swytchbackInputStyles {
  /* minHeight: 100px; */ /* Commented out because minHeight in CSS requires a unit */
  font-size: 16px;
  line-height: 1.4;
  color: #ffffff;
}

/* Styles for placeholder text in swytchbackInputStyles */
.swytchbackInputStyles::placeholder {
  opacity: 0.5;
}

.swytchbackInputStyles {
  text-align: left;
}

.swytchbackInputLabelStyles {
  font-size: 18px;
  color: #ffffff;
}
