.swytchbackMenuItem {
  font-size: 14px;
}

.textField,
.numberTextField {
  margin: 0;
  width: 100%;
  color: #ffffff;
}

.textField .MuiOutlinedInput-root,
.numberTextField .MuiOutlinedInput-root {
  border-color: #ffffff;
  border-radius: 10px;
}

.textField .MuiOutlinedInput-root fieldset,
.numberTextField .MuiOutlinedInput-root fieldset {
  border-color: #ffffff;
}

.textField .MuiOutlinedInput-root:hover fieldset,
.numberTextField .MuiOutlinedInput-root:hover fieldset {
  border-color: #ffffff;
}

.textField .Mui-focused .MuiOutlinedInput-root fieldset,
.numberTextField .Mui-focused .MuiOutlinedInput-root fieldset {
  border-color: #ffffff;
}

.textField .Mui-disabled .MuiOutlinedInput-root fieldset,
.numberTextField .Mui-disabled .MuiOutlinedInput-root fieldset {
  border-color: #ffffff;
}

.swytchbackInputStyles {
  /* minHeight: 100px; */
  font-size: 16px;
  line-height: 1.4;
  color: #ffffff;
  text-align: left;
}

.swytchbackInputStyles::placeholder {
  opacity: 0.5;
}

.swytchbackInputLabelStyles {
  font-size: 18px;
  color: #ffffff;
}
