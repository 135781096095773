div.tableStyle {
  position: relative;
  backgroundColor: #1997fc;
}

.singleButtonStyle {
  display: inline-block;
  width: 100%;
  margin: 0;
  height: 50%;
  border-radius: 12px;
  border: 2px solid #ffffff;
  background-color: #136ffb;
  font-size: 24px;
  font-weight: 500;
  outline: none !important;
}

.singleButtonStyle:focus,
.singleButtonStyle:hover,
.singleButtonStyle:active {
  outline: none !important;
  background-color: #136ffb !important;
}

.leftButtonStyle {
  position: relative;
  left: 40;
  top: 10;
  border: 0;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  background-color: #f7f6f7;
  webkit-box-shadow: 1px 1px 3px 3px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  moz-box-shadow: 1px 1px 3px 3px #ccc;  /* Firefox 3.5 - 3.6 */
  box-shadow: 1px 1px 3px 3px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

.rightButtonStyle {
  position: relative;
  right: 40;
  top: 10;
  border: 0;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  background-color: #f7f6f7;
  webkit-box-shadow: 1px 1px 3px 3px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  moz-box-shadow: 1px 1px 3px 3px #ccc;  /* Firefox 3.5 - 3.6 */
  box-shadow: 1px 1px 3px 3px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

.scrollingDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: none;
  overflow-y: scroll;
  width: 100%;
  padding-top: 10vh;
  height: 100vh;
  z-index: 6;
  -webkit-overflow-scrolling: touch;
}

.containerDiv {
  height: 100vh;
  background-color: '#000000';
  z-index: -2;
}
