.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.iconButtonContainer {
  margin: 0;
  padding: 0;
}

.spaced {
  margin: 0;
  padding: 4px; /* assuming you meant to include a unit */
}

.icon {
  margin: 6px;
  font-size: 26px;
}

.title {
  color: #2c3e50;
  border-bottom: 1px solid #ebebeb;
  font-size: 16px;
  margin-bottom: 6px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #f7f7f7;
}

.buttonContainerStyle {
  padding: 10px;
}

.buttonStyle {
  margin-bottom: 6px;
  margin-left: 6px;
  margin-right: 6px;
  font-size: 14px;
}