/* styles.css */

.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.tableContainerStyle {
  padding: 20px;
}

.centeredContainerDivStyle {
  margin-top: 0;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.numberTextField {
  margin-left: 6px;
  margin-right: 6px;
  width: 80px;
}

.textField {
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
}

.swytchbackInputStyles {
  font-size: 14px;
}

.swytchbackInputLabelStyles {
  font-size: 16px;
}

.toggleRowDivStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 60px;
  width: 100%;
}

.columnLabelDivStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
}

.columnDataDivStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
}
